const Faqs = [
  {
    title: 'works.title',
    faqs: [
      {
        title: 'works.faqs.f1.title',
        description: 'works.faqs.f1.description'
      },
      {
        title: 'works.faqs.f2.title',
        description: 'works.faqs.f2.description'
      },
      {
        title: 'works.faqs.f3.title',
        description: 'works.faqs.f3.description'
      },
      {
        title: 'works.faqs.f4.title',
        description: 'works.faqs.f4.description'
      }
    ]
  },
  {
    title: 'transporter.title',
    faqs: [
      {
        title: 'transporter.faqs.f1.title',
        description: 'transporter.faqs.f1.description'
      },
      {
        title: 'transporter.faqs.f2.title',
        description: 'transporter.faqs.f2.description'
      },
      {
        title: 'transporter.faqs.f3.title',
        description: 'transporter.faqs.f3.description'
      },
      {
        title: 'transporter.faqs.f4.title',
        description: 'transporter.faqs.f4.description'
      }
    ]
  },
  {
    title: 'partners.title',
    faqs: [
      {
        title: 'partners.faqs.f1.title',
        description: 'partners.faqs.f1.description'
      },
      {
        title: 'partners.faqs.f2.title',
        description: 'partners.faqs.f2.description'
      },
      {
        title: 'partners.faqs.f3.title',
        description: 'partners.faqs.f3.description'
      },
      {
        title: 'partners.faqs.f4.title',
        description: 'partners.faqs.f4.description'
      }
    ]
  }
];

export default Faqs;
