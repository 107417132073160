import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import FaqsHeader from 'components/sections/faqs-header';
import FaqsList from 'components/sections/faqs-list';
import Layout from 'components/layout';
import SEO from 'components/seo';

import FAQs from '@youship/faqs';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const bannerImage = 'images/create-account.png';

const bannerButtons = [
  {
    external: true,
    linkProps: {
      to: `${WEB_APP_URL}/register`
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'faqs.banner.buttons.sign_up'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'faqs.banner.buttons.contacts'
  }
];

const FaqsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="faqs.title" />
    <FaqsHeader />
    <FaqsList lists={FAQs} />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="faqs.banner.text"
      title="faqs.banner.title"
    />
  </Layout>
);

export default FaqsPage;
