import React from 'react';
import { FormattedMessage} from 'react-intl';

import './styles.scss';

const FaqsHeader = () => (
  <div className="faqs-header">
    <div className="container">
      <div className="row">
        <div className="col col-12">
          <h6><FormattedMessage id="faqs.header.title" /></h6>
          <h2 className="h2--display"><FormattedMessage id="faqs.header.description" /></h2>
        </div>
      </div>
    </div>
  </div>
);

export default FaqsHeader;
